<template>
  <div id="text-area-field">
    <span
      v-if="!openLabelInput && label"
      class="label"
      @click="handleOpenInput"
    >
      <FormFieldLabel
        v-if="label"
        :label="label"
        :is-mandatory="isMandatory"
        :tooltip="tooltip"
        :transform="transform"
      />
    </span>

    <input
      v-if="openLabelInput"
      ref="focusMe"
      :value="label"
      type="text"
      autofocus
      class="label1"
      :class="!labelError ? 'q-mb-sm' : ''"
      @input="handleLabelInput"
      @mouseleave="handleFocusOut(label)"
    />

    <FormFieldError v-if="labelError" :error="labelError" class="q-mb-sm" />

    <FormFieldWrapper
      :is-focused="isFocused"
      :is-clearable="false"
      :is-readonly="isReadonly"
      :is-disabled="isDisabled"
      :has-error="!!error"
    >
      <div id="tip-tap-editor">
        <!-- toolbar -->

        <Toolbar
          @set-heading="setHeading"
          @unset-heading="unsetHeading"
          @toggle-alignment="toggleAlignment"
          @toggle-bold="toggleBold"
          @toggle-italic="toggleItalic"
          @toggle-underline="toggleUnderline"
          @toggle-bullet-list="toggleBulletList"
          @toggle-number-list="toggleNumberList"
          @increase-indent="increaseIndent"
          @decrease-indent="decreaseIndent"
          @set-text-color="setTextColor"
          @unset-text-color="unsetTextColor"
          @set-bg-color="setBgColor"
          @unset-bg-color="unsetBgColor"
          @undo="undo"
          @redo="redo"
          @set-link="setLink"
        />

        <!-- ... -->

        <!-- content -->
        <BaseScrollbar height="calc(100vh - 465px)">
          <div v-if="htmlcontent">
            <div
              contenteditable="true"
              style="min-height: 100px"
              @input="updateContent"
              @blur="updateContentBlur"
              v-html="value"
            ></div>

            <!-- Save Button -->
          </div>
          <EditorContent v-else :editor="editor" />
        </BaseScrollbar>

        <!-- ... -->
      </div>
    </FormFieldWrapper>

    <FormFieldError v-if="error" :error="error" />
  </div>
</template>

<script>
import Toolbar from "./components/toolbar/Toolbar.vue";
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import { Color } from "@tiptap/extension-color";
import Highlight from "@tiptap/extension-highlight";
import Placeholder from "@tiptap/extension-placeholder";
import FieldPlaceholder from "./components/field-placeholder/extension.js";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldWrapper from "@/components/common/form/field-wrapper/FormFieldWrapper.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";

export default {
  name: "TextEditor",

  components: {
    Toolbar,
    EditorContent,
    FormFieldLabel,
    FormFieldWrapper,
    FormFieldError,
  },

  props: {
    value: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },

    autoFocus: {
      type: Boolean,
      default: false,
    },

    height: {
      type: String,
      default: "",
    },

    labelEdit: {
      type: Boolean,
      default: false,
    },

    panels: {
      type: Array,
      default: () => [],
    },
    htmlcontent: {
      type: Boolean,
      default: false,
    },

    transform: {
      type: String,
      default: "transform",
    },
  },

  data() {
    return {
      editor: null,
      isFocused: false,
      openLabelInput: false,
      labelError: "",
      tempValue: "",
      selectedText: {},
      savedRange: null,
    };
  },

  watch: {
    editor: {
      deep: true,
      handler() {
        this.$emit("input", this.editor.getHTML());
      },
    },
  },

  created() {
    this.initiateEditor();
  },

  beforeDestroy() {
    this.editor.destroy();
    document.removeEventListener("mouseup", this.handleSelection);
  },
  mounted() {
    document.addEventListener("mouseup", this.handleSelection);
  },
  methods: {
    updateContent(event) {
      this.tempValue = event.target.innerHTML;
      this.$emit("temp", this.tempValue);
    },

    updateContentBlur(event) {
      this.tempValue = event.target.innerHTML; // Update value with edited content
      this.$emit("tempBlur", this.tempValue);
    },
    handleSelection() {
      this.selectedText = window.getSelection();
      if (this.selectedText.rangeCount > 0) {
        this.savedRange = this.selectedText.getRangeAt(0).cloneRange(); // Store the range
        //console.log("Selection captured and range saved.", this.savedRange);
      }
    },
    // Save content (this could also be an API call or store the updated value)
    saveContent() {
      this.value = this.tempValue; // Update the main value with the edited content
      //console.log("Saved HTML content:", this.value);
      // You can make an API call or store the updated value here
    },

    toggleBold() {
      if (this.htmlcontent) {
        const selection = window.getSelection(); // Get the current selection
        if (selection.rangeCount > 0) {
          const range = selection.getRangeAt(0); // Get the selected range
          const selectedText = range.toString(); // Get the selected text

          // If there is selected text, wrap it with <strong> tag
          if (selectedText) {
            // Create a <strong> element
            const strong = document.createElement("strong");
            strong.textContent = selectedText; // Set the selected text as the content of the <strong> element

            // Replace the selected text with the <strong> element in the range
            range.deleteContents(); // Remove the selected text
            range.insertNode(strong); // Insert the <strong> element

            // Clear the selection
            selection.removeAllRanges();
            selection.addRange(range); // Restore the selection

            // Update the value to reflect changes
            this.updateContent({ target: { innerHTML: this.value } });
          }
        }
      } else {
        this.executeCommand("bold");
      }
    },

    // Italic formatting for selected text
    toggleItalic() {
      if (this.htmlcontent) {
        const selection = window.getSelection(); // Get the current selection
        if (selection.rangeCount > 0) {
          const range = selection.getRangeAt(0); // Get the selected range
          const selectedText = range.toString(); // Get the selected text

          // If there is selected text, wrap it with <i> tag
          if (selectedText) {
            // Create an <i> element
            const italic = document.createElement("i");
            italic.textContent = selectedText; // Set the selected text as the content of the <i> element

            // Replace the selected text with the <i> element in the range
            range.deleteContents(); // Remove the selected text
            range.insertNode(italic); // Insert the <i> element

            // Clear the selection
            selection.removeAllRanges();
            selection.addRange(range); // Restore the selection

            // Update the value to reflect changes
            this.updateContent({ target: { innerHTML: this.value } });
          }
        }
      } else {
        this.executeCommand("italic");
      }
    },

    // Underline formatting for selected text

    // Execute a command (bold, italic, etc.) on the contenteditable div
    executeCommand(command) {
      // Focus on the contenteditable div to ensure the selection is active
      this.$refs.editableDiv.focus();

      // Apply the formatting command to the selected text
      document.execCommand(command, false, null);

      // Update the content after the command
      this.updateContent({ target: this.$refs.editableDiv });
    },
    initiateEditor() {
      //console.log(this.value, "value123");
      this.editor = new Editor({
        content: this.value,
        extensions: [
          StarterKit,
          Link,
          Underline,
          TextAlign.configure({
            types: ["heading", "paragraph"],
          }),
          TextStyle,
          Color,
          Highlight.configure({
            multicolor: true,
          }),
          Placeholder.configure({
            placeholder: "Start typing …",
          }),
          FieldPlaceholder,
        ],
      });
    },

    setHeading(level) {
      if (this.htmlcontent) {
        const selection = window.getSelection(); // Get the current selection
        if (selection.rangeCount > 0) {
          const range = selection.getRangeAt(0); // Get the selected range
          const selectedText = range.toString(); // Get the selected text

          // If there is selected text
          if (selectedText) {
            // Check if the selected text is already wrapped in a heading tag
            const parentElement = range.startContainer.parentNode;

            // Check if the parent element is a heading tag
            if (parentElement.tagName.toLowerCase().startsWith("h")) {
              // Remove the existing heading tag
              const textNode = document.createTextNode(selectedText);
              range.deleteContents(); // Remove the selected text
              range.insertNode(textNode); // Insert plain text
            }

            // Create a new heading element
            const heading = document.createElement("h" + level);
            heading.textContent = selectedText; // Set the selected text as the content of the heading

            // Replace the selected text (or plain text) with the heading element
            range.deleteContents(); // Remove any remaining selected text
            range.insertNode(heading); // Insert the heading element

            // Clear the selection
            selection.removeAllRanges();
            selection.addRange(range); // Restore the selection

            // Update the value to reflect changes
            this.updateContent({ target: { innerHTML: this.value } });
          }
        }
      } else {
        this.editor.chain().focus().toggleHeading({ level }).run();
      }
    },

    unsetHeading() {
      this.editor.chain().focus().clearNodes().unsetAllMarks().run();
    },

    toggleAlignment(alignment) {
      if (this.htmlcontent) {
        const selection = window.getSelection(); // Get the current selection
        if (selection.rangeCount > 0) {
          const range = selection.getRangeAt(0); // Get the selected range
          const selectedText = range.toString(); // Get the selected text

          // If there is selected text
          if (selectedText) {
            // Check if the selected text is already wrapped in an alignment tag (e.g., <div>)
            const parentElement = range.startContainer.parentNode;

            // If the parent is a block-level element, we need to replace it
            if (
              parentElement.tagName.toLowerCase() === "div" &&
              parentElement.style.textAlign
            ) {
              // Remove the existing alignment by replacing the parent div with its content
              const textNode = document.createTextNode(selectedText);
              range.deleteContents(); // Remove the selected text
              parentElement.replaceWith(textNode); // Replace the parent div with plain text
            }

            // Create a new block-level element (e.g., <div>) for the new alignment
            const alignedDiv = document.createElement("div");
            alignedDiv.style.textAlign = alignment; // Set the text alignment

            // Wrap the selected text with the new aligned element
            alignedDiv.textContent = selectedText; // Set the selected text as the content

            // Replace the selected text (or any plain text) with the new aligned element
            range.deleteContents(); // Remove any remaining selected text
            range.insertNode(alignedDiv); // Insert the aligned div

            // Clear the selection
            selection.removeAllRanges();
            selection.addRange(range); // Restore the selection

            // Update the value to reflect changes
            this.updateContent({ target: { innerHTML: this.value } });
          }
        }
      } else {
        // For external editor (like ProseMirror, Quill, etc.), handle the alignment
        this.editor.chain().focus().setTextAlign(alignment).run();
      }
    },

    // toggleBold() {
    //   this.editor.chain().focus().toggleBold().run();
    // },

    // toggleItalic() {
    //   this.editor.chain().focus().toggleItalic().run();
    // },
    toggleUnderline() {
      if (this.htmlcontent) {
        const selection = window.getSelection(); // Get the current selection
        if (selection.rangeCount > 0) {
          const range = selection.getRangeAt(0); // Get the selected range
          const selectedText = range.toString(); // Get the selected text

          // If there is selected text
          if (selectedText) {
            // Check if the selected text is already underlined
            const parentElement = range.startContainer.parentNode;

            if (parentElement.style.textDecoration === "underline") {
              // If already underlined, remove the underline by replacing with plain text
              const textNode = document.createTextNode(selectedText);
              range.deleteContents(); // Remove the selected text
              parentElement.replaceWith(textNode); // Replace the underline span with plain text
            } else {
              // Create a new <span> element with underline style
              const underlineSpan = document.createElement("span");
              underlineSpan.style.textDecoration = "underline"; // Set the text decoration to underline

              // Wrap the selected text with the <span> element
              underlineSpan.textContent = selectedText; // Set the selected text as the content of the <span>

              // Replace the selected text with the <span> element in the range
              range.deleteContents(); // Remove the selected text
              range.insertNode(underlineSpan); // Insert the <span> element
            }

            // Clear the selection
            selection.removeAllRanges();
            selection.addRange(range); // Restore the selection

            // Update the value to reflect changes
            this.updateContent({ target: { innerHTML: this.value } });
          }
        }
      } else {
        // For external editor (like ProseMirror, Quill, etc.), handle the underline
        this.editor.chain().focus().toggleUnderline().run();
      }
    },

    toggleBulletList() {
      this.editor.chain().focus().toggleBulletList().run();
    },

    toggleNumberList() {
      this.editor.chain().focus().toggleOrderedList().run();
    },

    increaseIndent() {
      this.editor.chain().focus().sinkListItem("listItem").run();
    },

    decreaseIndent() {
      this.editor.chain().focus().liftListItem("listItem").run();
    },

    undo() {
      this.editor.chain().focus().undo().run();
    },

    redo() {
      this.editor.chain().focus().redo().run();
    },

    setLink() {
      const previousUrl = this.editor.getAttributes("link").href;
      const url = window.prompt("URL", previousUrl);

      // cancelled
      if (url === null) {
        return;
      }

      // empty
      if (!url) {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run();

        return;
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    },

    setTextColor(color) {
      //console.log(this.savedRange);
      if (this.htmlcontent && this.savedRange) {
        // Use savedRange instead of selectedText
        const range = this.savedRange.cloneRange(); // Clone again to avoid issues during DOM manipulation

        const selectedText = range.toString();
        if (selectedText) {
          const parentElement = range.startContainer.parentNode;

          // Case 1: If the parent element has a color style, update the color
          if (parentElement.style && parentElement.style.color) {
            parentElement.style.color = color;
          } else {
            // Case 2: Create a <span> with the selected color if no color is applied
            const colorSpan = document.createElement("span");
            colorSpan.style.color = color; // Set the text color

            const contents = range.extractContents(); // Extract the contents from the range
            colorSpan.appendChild(contents); // Append the extracted contents to the color span

            // Insert the colored <span> into the range
            range.insertNode(colorSpan);
          }

          // Clear the selection, but keep the range stored
          this.selectedText.removeAllRanges();
        }
      } else {
        // For external editor integrations (like ProseMirror, Quill), handle the color setting here
        //console.log("External editor color application not implemented.");
      }
    },

    unsetTextColor() {
      this.editor.chain().focus().unsetColor().run();
    },

    setBgColor(color) {
      console.log(color);
      // if (this.htmlcontent) {
      //   // If there is selected text
      //   if (selectedText) {
      //     // Create a new <span> for the background color
      //     const bgColorSpan = document.createElement("span");
      //     bgColorSpan.style.backgroundColor = color; // Set the background color

      //     // Extract the contents of the range (selected text)
      //     const fragment = range.extractContents(); // Extract the selected text from the DOM

      //     // Append the extracted contents (selected text) to the new span
      //     bgColorSpan.appendChild(fragment);

      //     // Insert the span (with the selected text) back into the range
      //     range.insertNode(bgColorSpan); // Insert the colored <span> at the current range

      //     // Clear the selection
      //     selection.removeAllRanges();

      //     // Restore the selection around the newly created <span>
      //     selection.addRange(range); // Restore the selection

      //     // Update the value to reflect changes
      //     this.updateContent({ target: { innerHTML: this.value } });
      //   }
      // } else {
      //   // For external editor (like ProseMirror, Quill, etc.), handle the background color setting
      //   this.editor.chain().focus().setHighlight({ color }).run();
      // }
    },

    unsetBgColor() {
      this.editor.chain().focus().unsetHighlight().run();
    },

    handleFocusOut(label) {
      if (!label || this.labelError) {
        return;
      }
      this.openLabelInput = false;
    },

    handleLabelInput(evt) {
      let label = evt.target.value;
      this.labelError = "";
      let labelFound = false;
      this.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        for (let field of panel.fields) {
          if (field.label.toLowerCase() === label.toLowerCase()) {
            labelFound = true;
            return;
          }
        }
      });
      if (labelFound) {
        this.labelError = "Specified label already assigned";
      }
      this.$emit("update:label", evt.target.value);
    },

    handleOpenInput() {
      if (this.labelEdit) {
        this.openLabelInput = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#tip-tap-editor {
  margin: 16px;
}

.label1 {
  color: var(--icon-color);
  font-weight: 500;
  font-size: 13px;
  line-height: 1.25rem;
}

.label:hover {
  cursor: auto;
}
</style>
